<!--
--------------------------------------------------------------------------------
<copyright file="SalesCustomersABC.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('widget.abc_customer_sales') }}</summary>
      <p class="text">{{ $t('widget_info_text.abc_customer_sales_txt') }}</p>
      <p class="tip list-item tip-bkg">{{ $t('widget_info_text.tip') }}:</p>
      <p class="text tip-bkg">
        {{ $t('widget_info_text.quotes_abc_customers_tip') }}
      </p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.quotes_abc_customers_categories') }}</summary>
      <p class="text">{{ $t('widget_info_text.customers_groups_abc') }}</p>
      <ul class="list">
        <li class="list-item">
          <span class="demi-bold">{{ $t('widget_info_text.customer_group_a') }}</span>
          - {{ $t('widget_info_text.customer_group_a_txt') }}
        </li>
        <li class="list-item">
          <span class="demi-bold">{{ $t('widget_info_text.customer_group_b') }}</span>
          - {{ $t('widget_info_text.customer_group_b_txt') }}
        </li>
        <li class="list-item">
          <span class="demi-bold">{{ $t('widget_info_text.customer_group_c') }}</span>
          - {{ $t('widget_info_text.customer_group_c_txt') }}
        </li>
      </ul>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.abc_customer_sales_series') }}</summary>
      <p class="text">{{ $t('widget_info_text.abc_customer_sales_series_txt') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.expand') }}</summary>
      <img src="images/info/expand.jpg" class="list-item" />
      <p>{{ $t('widget_info_text.abc_customer_sales_detailed_txt') }}</p>
    </details>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
